<template>
  <div class="mybets">
    <v-container>
      <v-row dense>
        <v-col align="center">
          <h1>My Bets</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          
          <v-tabs v-model="betStatus" :show-arrows="false">
            <v-tab href="#active-bets">
              Active Bets
            </v-tab>
            <v-tab href="#settled-bets">
              Settled Bets
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="betStatus">
            <v-tab-item value="active-bets">

              <div v-if="active.length == 0">
                <v-alert
                  border="right"
                  color="blue-grey"
                  dark
                  class="my-4 text-center"
                >
                  There are no bets to show yet!
                </v-alert>
              </div>
              <div v-else>
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  max-width="100%"
                  type="table"
                ></v-skeleton-loader>
                <v-simple-table v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" width="500">
                          Cards
                        </th>
                        <th class="text-center">
                          Date
                        </th>
                        <th class="text-center">
                          Hand
                        </th>
                        <th class="text-center">
                          Wager 
                        </th>
                        <th class="text-center">
                          Outcome 
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in active" :key="index">
                        <td align="center">
                          <table>
                            <tr>
                              <td
                              v-for="(bet, i) in item.cards"
                              :key="i">
                                <game-build-hand-card 
                                  :id="i"
                                  :cardValue="bet.value.toString()"
                                  :cardSuit="bet.suit"
                                  :which="bet.which"
                                  :event="bet.event"
                                  :team="bet.team"
                                  :sport="bet.sport"
                                  :bet="bet.bet"
                                  :showClose="false"
                                  :isMini="true"
                                >
                                </game-build-hand-card>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td align="center">{{ formatDateTimeFull(item.created_at) }}</td>
                        <td align="center">{{ item.hand_label }}</td>
                        <td align="center">${{ item.wager }}</td>
                        <td align="center">{{ setOutcome(item.outcome) }}</td>
                      </tr>
                    </tbody>

                  </template>
                </v-simple-table>
              </div>

            </v-tab-item>
            <v-tab-item value="settled-bets">

              <div v-if="settled.length == 0">
                <v-alert
                  border="right"
                  color="blue-grey"
                  dark
                  class="my-4 text-center"
                >
                  There are no bets to show yet!
                </v-alert>
              </div>
              <div v-else>
                <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  max-width="100%"
                  type="table"
                ></v-skeleton-loader>
                <v-simple-table v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" width="500">
                          Cards
                        </th>
                        <th class="text-center">
                          Date
                        </th>
                        <th class="text-center">
                          Hand
                        </th>
                        <th class="text-center">
                          Wager 
                        </th>
                        <th class="text-center">
                          Outcome 
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in settled" :key="index">
                        <td align="center">
                          <table>
                            <tr>
                              <td
                              v-for="(bet, i) in item.cards"
                              :key="i">
                                <game-build-hand-card 
                                  :id="i"
                                  :cardValue="bet.value"
                                  :cardSuit="bet.suit"
                                  :which="bet.which"
                                  :event="bet.event"
                                  :team="bet.team"
                                  :sport="bet.sport"
                                  :bet="bet.bet"
                                  :showClose="false"
                                  :isMini="true"
                                >
                                </game-build-hand-card>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td align="center">{{ formatDateTimeFull(item.created_at) }}</td>
                        <td align="center">{{ item.hand_label }}</td>
                        <td align="center">${{ item.wager }}</td>
                        <td align="center">{{ setOutcome(item.outcome) }}</td>
                      </tr>
                    </tbody>

                  </template>
                </v-simple-table>
              </div>

            </v-tab-item>
          </v-tabs-items>
          
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import Moment from 'moment';
  import ApiService from "@/services/ApiService.js";

  export default {
    name: 'MyBets.vue',
    data(){
      return{
        betStatus: 0,
        active: [],
        settled: [],
        loading: true,
        totalAmount: 0,
      }
    },
    computed:{
      getUserID() {
        return this.$store.getters.getUserID;
      },
    },
    created(){
      this.load();
    },
    methods: {
      submit () {
        this.$root.snackbar.message = 'This is for demo purposes only!';
        this.$root.snackbar.open = true;
      },
      formatDateTimeFull(date){
        return Moment(date).format("ddd MMM Do YYYY, h:mm A");
      },
      setOutcome(id){
        switch(id){
          case 0:
            return "In Progress";
          case 1:
            return "Loss";
          case 2:
            return "Win";
        }
      },
      async load(){
        let self = this;
        let user_id = this.getUserID;
        return new Promise(() => {
          ApiService
            .post("/bets/get-all", {
              user_id: user_id
            })
            .then(response => {
              self.active = response.data;
              self.loading = false;
            })
        });
      },
    },
  }
</script>

<style lang="scss">
  .mybets{
    table{
      width: 100%;
      table-layout: fixed;
    }
  }
</style>
